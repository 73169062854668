import React, { useState, useEffect } from "react"
import DashboardTutorComponent from ".."
import { me } from "../../../services/auth"
import { formatUang } from "../../../services/utils"
import Button from "../../Reusable/Button"
import Penarikan from "./penarikan"
import styled from "styled-components"
import { getWithdrawals } from "../../../services/user"
import Riwayat from "./riwayat"

const Balance = ({ user }) => {
  const [wallet, setWallet] = useState({})
  const [riwayat, setRiwayat] = useState([])

  useEffect(() => {
    me().then(response => {
      response?.data?.wallet && setWallet(response.data.wallet)
    })
  }, [user])

  useEffect(() => {
    getRiwayat()
  }, [user])

  const getRiwayat = () => {
    getWithdrawals().then(resp => {
      if (resp.status === 200) setRiwayat(resp.data.results)
    })
  }

  return (
    <DashboardTutorComponent chosen={1}>
      <Container>
        <Section>
          <WalletItemContainer>
            <WalletItem>
              <Nominal>
                Rp {formatUang(wallet?.balance) || "loading..."}
              </Nominal>
              <Label>Balance</Label>
            </WalletItem>

            <WalletItem>
              <Nominal>
                Rp {formatUang(wallet?.accumulated) || "loading..."}
              </Nominal>
              <Label>Total</Label>
            </WalletItem>
          </WalletItemContainer>
        </Section>

        <Section>
          <ButtonContainer>
            <Button className="dompet-button" disabled={true}>
              Pengaturan Dompet
            </Button>
          </ButtonContainer>
        </Section>

        <Section>
          <Penarikan wallet={wallet} getRiwayat={getRiwayat} />
        </Section>

        <Section>
          <h4>Riwayat Penarikan</h4>
          {riwayat?.length > 0
            ? riwayat.map((item, index) => (
                <Riwayat riwayat={item} key={index} />
              ))
            : "Belum ada riwayat penarikan"}
        </Section>

        <Section>
          <h4>Ada Pertanyaan?</h4>
          <Button newPage href="https://wa.me/6281383736364">
            Hubungi CS Purwalenta
          </Button>
        </Section>
      </Container>
    </DashboardTutorComponent>
  )
}

const Container = styled.div`
  max-width: 640px;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
`

const Section = styled.div`
  margin-top: 2rem;
  margin-bottom: 3rem;
`

const WalletItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`

const WalletItem = styled.div`
  text-align: center;
  padding: 2rem;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
`
const Nominal = styled.div`
  padding-bottom: 2rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
`

const Label = styled.div`
  color: rgb(55 65 81);
  font-weight: 600;
`

const ButtonContainer = styled.div`
  .dompet-button {
    margin-left: auto;
    margin-right: auto;
  }
`

export default Balance
