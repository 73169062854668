import React, { useState } from "react"
import styled from "styled-components"
import Input from "../../Reusable/Input"
import Textarea from "../../Reusable/Input/textarea"
import Button from "../../Reusable/Button"
import { requestWithdrawal } from "../../../services/user"
import { toast } from "react-toastify"

const Container = styled.div`
  .error {
    font-size: 0.75rem;
    color: red;
  }
`

const Penarikan = ({ wallet, getRiwayat }) => {
  const [inputField, setInputField] = useState({
    nominal: null,
    keterangan: "",
  })

  const inputsHandler = e => {
    setInputField({ ...inputField, [e.target.name]: e.target.value })
  }

  const submitPenarikan = () => {
    requestWithdrawal({
      nominal: inputField.nominal,
      note: inputField.keterangan,
      wallet: wallet.id,
    })
      .then(resp => {
        if (resp.status === 201) {
          toast.success("Berhasil mengirim request penarikan")
          setInputField({
            nominal: 0,
            keterangan: "",
          })
        } else {
          toast.error("Terjadi kesalahan")
        }
      })
      .finally(() => getRiwayat())
  }

  return (
    <Container>
      <h4>Request Penarikan</h4>
      <br />
      Nominal *
      <div className="error">
        {inputField.nominal < 10000 &&
          "Mohon masukkan nominal lebih besar dari Rp 10.000"}
        {inputField.nominal > wallet.balance && "Saldo anda tidak mencukupi."}
      </div>
      <Input
        placeholder="Masukkan nominal"
        type="number"
        min="10000"
        max={wallet.balance}
        step="10000"
        name="nominal"
        onChange={inputsHandler}
        value={inputField.nominal}
      />
      <br />
      <br />
      Keterangan
      <Textarea
        placeholder="Masukkan keterangan (optional)"
        name="keterangan"
        onChange={inputsHandler}
        value={inputField.keterangan}
      />
      <Button
        disabled={
          inputField.nominal < 10000 || inputField.nominal > wallet.balance
        }
        onClick={submitPenarikan}
      >
        Request Penarikan
      </Button>
    </Container>
  )
}

export default Penarikan
