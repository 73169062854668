import React from "react"
import styled from "styled-components"
import moment from "moment"
import { formatUang } from "../../../services/utils"

const Riwayat = ({ riwayat }) => {
  return (
    <RiwayatCard>
      <table>
        <tbody>
          <tr>
            <th>ID</th>
            <td>{riwayat.id}</td>
          </tr>
          <tr>
            <th>Waktu Request</th>
            <td>{moment(riwayat.created_at).format("LLL")}</td>
          </tr>
          {/* <tr>
            <th>Waktu Diproses</th>
            <td>
              {riwayat.processing_at
                ? moment(riwayat.processing_at).format("LLL")
                : "-"}
            </td>
          </tr>
          <tr>
            <th>Waktu Selesai</th>
            <td>
              {riwayat.processed_at
                ? moment(riwayat.processed_at).format("LLL")
                : "-"}
            </td>
          </tr> */}
          <tr>
            <th>Nominal</th>
            <td>Rp {formatUang(riwayat.nominal)}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>{riwayat.status}</td>
          </tr>
          <tr>
            <th>Keterangan</th>
            <td>{riwayat.note}</td>
          </tr>
        </tbody>
      </table>
    </RiwayatCard>
  )
}

export default Riwayat

const RiwayatCard = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 1rem;
  background: #f4f4f4;

  table {
    font-size: 0.875rem;
  }

  th {
    padding: 0.25rem 1rem;
    font-weight: 500;
  }

  td {
    padding: 0.25rem 1rem;
  }
`
